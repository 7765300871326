export const requestStatus = [
  {
    label: "New",
    value: "CREATED",
  },
  {
    label: "Follow Up",
    value: "FOLLOW_UP",
  },
  {
    label: "Fullfilled",
    value: "FULLFILLED",
  },
];
